.greeting{
    font-family: 'Baloo Bhai 2', cursive;
    margin-left: 3rem;
    position: relative;
    margin-bottom: 12px;
    font-size: 28px;
}
.head{
   background-image: url(../svg/welcome-bk.svg);


color:rgb(255, 255, 255);
}